<template>
    <div class="FinancialManagement">
            <div
            class="FinancialManagement_left"
            :class="{ FinancialManagement_left1: show == false }"
            >
            <el-menu class="el_menu" :default-active="$route.path" router v-show="show" :default-openeds="['/Finance']">
              <div v-for="(item, index) in nav_menu_data" :key="index">
                <div v-show="item.menulist">
                  <el-submenu :index="item.path">
                    <span slot="title">{{item.title}}</span>
                    <div v-for="(iten ,indexs) in item.menulist" :key="indexs">
                      <el-menu-item :index="iten.path">
                        <span>{{iten.title}}</span>
                      </el-menu-item>
                    </div>
                  </el-submenu>
                </div>
                <div v-show="!item.menulist" class="menu-no-child">
                  <el-menu-item
                    :index="item.path"
                  >
                    <span slot="title">{{ item.title }}</span>
                  </el-menu-item>
                </div>
              </div>
            </el-menu>
            </div>
            <div class="FinancialManagement_center">
            <img src="../../assets/image/guan.png" @click="close" v-show="show"/>
            <img src="../../assets/image/kai.png" @click="open" v-show="!show"/>
            </div>
            <div
             class="FinancialManagement_right"
            :class="{ FinancialManagement_right1: show == false }"
            >
            <router-view></router-view>
            </div>
    </div>
</template>
<script>
export default {
    data(){
      return{
      show: true,
      nav_menu_data: [
        {
          title: "科目表",
          path: "/ChartOfAccounts",
        },
        {
          title: "财务资料",
          path: "/Finance",
          menulist: [
            {
              title:'相关文件',
              path: "/Finance/FinanceZ"
            },
            {
              title:'财务凭证',
              path: "/Finance/FinanceVoucher"
            },

          ]
        },
        {
          title: "拨款进度",
          path: "/Appropriationprogress",
        },
      ],
        }
    },
    methods:{
      close() {//关
        this.$store.commit("showflag", true);
        this.show = false;
      },
      open(){//开
        this.show = true;
        this.$store.commit("showflag", false);
      }
    },
}
</script>
<style lang="scss" scoped>
.FinancialManagement {
  width: 1920px;
  height: 100%;
  .FinancialManagement_left {
    width: 140px;
    height: 100%;
    float: left;
    transition: width 1s;
    background-color: white;
    box-shadow: 3px 0 6px rgba(0, 104, 163, 0.1);
    .el_menu{
        width: 140px;
      span {
        font-size: 18px;
      }
    }
  }
  .FinancialManagement_left1 {
    width: 0px;
  }
  .FinancialManagement_center {
    width: 30px;
    height: 100%;
    //background: chartreuse;
    float: left;
    position: relative;
    img {
      width: 30px;
      height: 30px;
      position: absolute;
      top: 45%;
    }
  }
  .FinancialManagement_right {
    width: 1750px;
    height: 100%;
    float: left;
    transition: width 1s;
  }
  .FinancialManagement_right1 {
    width: 1890px;
  }
}
/deep/ .el-menu-item.is-active {
  border-left: 4px solid #409eff;
  color: #303133;
  padding-left: 16px!important;
}
/deep/  .el-submenu .el-menu-item.is-active {
  border-left: 4px solid #409eff;
  color: #303133;
  padding-left: 36px!important;
}
</style>
